.login{
  width: 100%;
  height: 100%;
  background: url("../../bg.jpeg") no-repeat;
  background-size: 100% 100%;
  display: flex;
}
.login .ant-card{
  margin:0 auto;
  align-self: center;
}
