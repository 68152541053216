
.multimedia{
  padding: 24px;
}
.imgList .ant-upload-list-picture-card{
  position: relative;
}
.imgList .ant-upload-select-picture-card{
  position: absolute;
  left: 0;
  top:0;
  z-index: 99;
}
.low .ant-upload-list-picture-card-container:first-child{
  margin-left: 112px;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
