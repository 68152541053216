.home{
  background-image: url(../../assets/home_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 89px);
  position: relative;
}
.textRight{
  text-align: right;
}
.bg-Wight{
  background: rgba(255,255,255,.45);
  padding: 5px 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  font-weight: 500;
}
