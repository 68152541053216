
.banner-config{
  padding: 24px;
}
.card-wapper{
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}
.image-wapper{
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.banner-config Input{
  overflow: hidden;
  text-overflow:ellipsis; white-space: nowrap;
}
.banner-config .ant-input-disabled{
  color:rgba(0,0,0,.65)
}
.image-box{
  height: 422px;
  overflow-y: auto;
}

.image-wapper{
  width: 120px;
  height: 120px;
  border: #999 dashed 1px;
  float: left;
  margin: 2px;
  cursor: pointer;
}
.list-item{
  overflow: hidden;
  text-overflow:ellipsis; white-space: nowrap;
}