.contact-us{
  padding:24px;
}
.contact-us .btn-box{
  width: 72px;
  margin-right:12px;
  line-height: 32px;
}
.contact-us .btn-box>Button{
  margin-right:6px
}