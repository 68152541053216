
.multi-text{
  padding: 24px;
}
.multi-text.btn-box{
  width: 72px;
  float: right;
}
.bf-content{
  height: 800px!important;
}