
.menu-config{
  padding: 24px;
}
.menu-config .menu-list{
  /* border: 1px solid #d9d9d9; */
  padding: 10px 20px;
  border-radius: 8px;
  height: 800px;
  overflow: hidden;
}
.menu-config .ant-tree {
  margin: 20px 0 0 20px;
}
.menu-config .tree-item-box{
width: 380px;
}
.menu-config .btn-group{
  float: right;
  margin-right: 80px;
}
.menu-config .btn-group a{
  display: block;
  width: 14px;
  float: left;
  margin-left: 6px;
}
.menu-config .ant-tree-treenode{
  height: 36px;
  line-height: 36px;
}