.Menus {
  background-color: #001529;
  height: 100%;
  overflow-y: auto;
}
.Menus img{
  width: 14px;
  height: 14px;
  margin-right: 16px;
  margin-bottom: 2px;
}

.Menus .ant-menu-item:hover img{
  -webkit-filter: drop-shadow(0 0 0 #fff);
  filter: drop-shadow(0 0 0 #fff);
}

.Menus .ant-menu-submenu:hover img{
  -webkit-filter: drop-shadow(0 0 0 #fff);
  filter: drop-shadow(0 0 0 #fff);
}
