.article{
  padding: 24px;
}
.article .btn-box{
  width: 72px;
  margin-right:12px;
  line-height: 32px;
}
.article .btn-box>Button{
  margin-right:6px
}
.action-group{
  height: 32px;
  padding: 4px;
}
.action-group>Button{
  float: right;
}

.article .ant-avatar {
  border-radius: 0;
}

.page-container{
  height: 95%;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding: 10px 0;
} 

.ant-spin-container{
  height: 100%;
}
.ant-spin-nested-loading{
  height: 100%;
}
.ant-spin-container.ant-spin-blur{
  height: 100%;
}

.ant-drawer-body.edit-modal{
  overflow: hidden;
}
.ant-modal-body.edit-modal{
  max-height: 600px;
  overflow-y: auto;
}
.item-wrapper{
  position: relative;
  width: 226px;
  height: 152px;
  overflow: hidden;
  border-radius: 16px;
  margin: 0 auto 16px auto;
  background-color: #e9e9e9;
}
/* .item-wrapper:hover .item-add-wrapper{
  top:0;
}
.item-wrapper:hover .item-title-wrapper{
  bottom:0;
} */
.item-add-wrapper{
  height: 28px;
  line-height: 28px;
  width: 80px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px;
  right: 0;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: top 300ms;
  border-bottom-left-radius: 16px;
}
.item-title-wrapper{
  height: 32px;
  line-height: 32px;
  width: 226px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0px;
  text-align: center;
  color: #fff;
  transition: bottom 300ms;
}
.multi-wrapper{
  width: 226px;
  height: 152px;
  border-radius: 16px;
  background-color: #e9e9e9;
  text-align: center;
  margin: 0 auto 16px auto;
  position: relative;
}
.multi-wrapper>.multi-img{
  height: 226px;
  line-height: 152px;
}
.multi-wrapper>.multi-select{
  position: absolute;
  bottom: 10px;
  left:53px;
}
.multi-wrapper .ant-select-selection-placeholder,.multi-wrapper .ant-select-arrow{
  color:rgba(0,0,0,.6)
}